import React from "react";
import NetworkHero from "../NetworkHero/NetworkHero";
import Sponsor from "../../components/Sponsor/Sponsor";
import { sponsSection } from "./Sponsors.module.css";

import { spons21, sponsPast, web3, outreach, comm, insight, title, ip, legal, pp, smp, eco, innovation, venture, bank, gen } from "./Spons";
import MediaPartnersLogo from "../MediaPartnersLogos/MediaPartnersLogo";

export default function () {
  return (
    <>
      <NetworkHero
        img1="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fupload.wikimedia.org%2Fwikipedia%2Fcommons%2Fthumb%2Fe%2Fea%2FMatrixLogoFinal.JPG%2F175px-MatrixLogoFinal.JPG&f=1&nofb=1"
        img3="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcdn-images-1.medium.com%2Fmax%2F980%2F1*Z5Ld0GAPjZGoft6jAYTnLQ%402x.png&f=1&nofb=1"
        img4="https://upload.wikimedia.org/wikipedia/commons/0/08/Accel_%28Partners%29_2015_logo.png"
        img2="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Google_2015_logo.svg/1200px-Google_2015_logo.svg.png"
        img6="https://zenprospect-production.s3.amazonaws.com/uploads/pictures/60f4fc3aca72e000018e6b9e/picture"
        img5="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fblogs.microsoft.com%2Fwp-content%2Fuploads%2F2012%2F08%2F8867.Microsoft_5F00_Logo_2D00_for_2D00_screen.jpg&f=1&nofb=1"
        meet="meet our"
        context="partners"
        desc="Our partners have shown Conquest immense support and have been strong pillars in our quest to deliver the best."
      />
      {/* <div>{JSON.stringify(partners)}</div> */}
      <div className={sponsSection}>
        <Sponsor type="INVESTMENT PARTNERS" list={ip} />
        <Sponsor type="PLATFORM PARTNER" list={pp} />
        <Sponsor type="ECOSYSTEM PARTNERS" list={eco} /> 
        <Sponsor type="LEGAL PARTNERS" list={legal} /> 
        <Sponsor type="COMMUNITY PARTNERS" list={comm} />
        <Sponsor type="OUTREACH PARTNERS" list={outreach} />
        <Sponsor type="EXCLUSIVE INSIGHT PARTNER" list={insight} />
        <Sponsor type="STARTUP MEDIA PARTNER" list={smp} />
        <MediaPartnersLogo type="MEDIA PARTNERS" />
        
      </div>

      {/* <div className={sponsSection}>
      {partners ? <>
        {partners.map((p, id) => (
          <>
            {p.type === "sponsor" ?
            <>
              <Sponsor type={p.data.type[0].text} list={p.data.list} />
            </> : null}
          </>
          
        ))}
        </> : null}
        
      </div> */}
    </>
  );
}